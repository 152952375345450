import * as React from 'react';

export interface ITopHeaderProps {
}

export interface ITopHeaderState {
}

export default class TopHeader extends React.Component<ITopHeaderProps, ITopHeaderState> {
  constructor(props: ITopHeaderProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className='hidden lg:block'>
            <section>
        <div className=" px-6 m-auto bg-[#1f2937] text-white text-sm  ">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12 ">
            <div className="col-span-3"></div>
            <div className="col-span-3"><a className='cursor-pointer' href='tel:+436769212103'><strong>Telefon : </strong>+436769212103</a></div>
          </div>
        </div>
      </section>

      </div>
    );
  }
}
